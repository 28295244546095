* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Madera;
    font-weight: 500;
}
@font-face {
    font-family: "Madera";
    src: url("../../../assets/fonts/Madera_Thin.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Madera";
    src: url("../../../assets/fonts/Madera_Regular.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Madera";
    src: url("../../../assets/fonts/Madera_Bold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 50px 0;
}
section {
    width: 100%;
    max-width: 1600px;
    padding: 0 5rem;
    margin: 0 auto;
    margin-bottom: 1rem;
}
section.order-header .outer-main-box-header {
    border: 1px solid #E6E6E6;
    padding: 24px;
    border-radius: 10px;
}
.order-header .outer-main-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.order-header .order-date-sct h3 {
    margin: 0;
    font-size: 22px;
    line-height: 22px;
    font-weight: 500;
    font-family: Madera;
}
.order-header .outer-main-box-header .order-date-sct {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}
.order-header .order-detail-sct a.order-detail-link {
    color: #507D3C;
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    padding: 11px 0;
}
.order-header .order-detail-sct a.order-detail-link svg {
    margin-left: 10px;
    top: 2px;
    position: relative;
}
.order-header .outer-main-box-header .order-date-sct p {
    color: #808080;
    font-size: 18px;
    line-height: 23.4px;
}
.order-way-sct .outer-odr-way-sct {
    border-radius: 20px;
    background: #F2F2F2;
    padding: 24px;
    display: flex;
    align-items: center;
    grid-gap: 5rem;
}
.order-way-sct .outer-odr-way-sct .left-sct-odr {
    width: 100%;
    max-width: 397px;
    height: auto;
}
.order-way-sct .outer-odr-way-sct .right-sct-odr {
    width: 100%;
    max-width: calc(100% - 397px);
    height: auto;
}
.order-way-sct .outer-odr-way-sct .left-sct-odr img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.order-way-sct .right-sct-odr h4 {
    margin-bottom: 10px;
    font-size: 22px;
    line-height: 28.6px;
}
.order-way-sct .right-sct-odr .progressbar {
    margin-top: 30px;
    position: relative;
}
.order-way-sct .right-sct-odr .progressbar .progress-bar {
    height: 16px;
    width: 100%;
    max-width: 100%;
    background: #D9D9D9;
    border-radius: 100px;
}
.order-way-sct .right-sct-odr .progressbar .progress-bar-fillment {
    width: 100%;
    max-width: 62px;
    height: 16px;
    background: #507D3C;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
}
.track-odr-sct .outer-sct-track {
    border: 1px solid #F2F2F2;
    border-radius: 14px;
}
.track-odr-sct .outer-sct-track .inner-track-sct {
    width: 100%;
    max-width: max-content;
    margin: 48px auto;
    position: relative;
}
.track-odr-sct .outer-sct-track .inner-track-sct .track-item {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    position: relative;
    padding: 10px 0;
}
.order-way-sct .inner-content-odr h4 b {
    font-weight: 600;
}
.order-way-sct .right-sct-odr h2 {
    font-size: 28px;
    line-height: 33.6px;
}
.track-odr-sct .outer-sct-track .track-item .icon-sct-track .svg-icon-sct {
    background: #D9D9D9;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 4px solid #fff;
    position: relative;
    z-index: 1;
    padding: 12px;
}
.track-odr-sct .track-item .progress-bar-track .progress-bar-line {
    background: #EDEDED;
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 25px;
    z-index: 0;
}
.track-odr-sct .track-item .content-sct-track p {
    color: #808080;
    font-size: 18px;
    line-height: 21.6px;
}
.track-odr-sct .track-item .content-sct-track h4 {
    color: #507D3C;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
}
.track-odr-sct .track-item .content-sct-track h2 {
    color: #131313;
    font-size: 28px;
    font-weight: 500;
    margin: 5px 0;
}
.track-odr-sct .outer-sct-track .inner-track-sct .track-item .content-sct-track {
    padding-left: 46px;
    border-bottom: 2px solid #dbdbdb;
    padding-bottom: 13px;
    padding-top: 9px;
    width: 100%;
}
.track-odr-sct .outer-sct-track .inner-track-sct .track-item:last-child .content-sct-track {
    padding-bottom: 0;
    border-bottom: 0;
}
.track-odr-sct .outer-sct-track .track-item.active-section .icon-sct-track .svg-icon-sct {
    background: #507D3C;
}
.track-odr-sct .outer-sct-track .inner-track-sct .progress-bar-track {
    position: absolute;
    height: 100%;
    width: 55px;
}
.track-odr-sct .outer-sct-track .inner-track-sct .progress-bar-track .progress-bar-line {
    width: 5px;
    background: #EDEDED;
    height: calc(100% - 11rem);
    left: 25px;
    position: absolute;
    top: 6rem;
}
.track-odr-sct .outer-sct-track .inner-track-sct .progress-bar-track .progress-bar-line-filled {
    background: #507D3C;
    height: 4%;
    width: 5px;
    left: 25px;
    position: absolute;
    top: 6rem;
}


/* media query */
@media (max-width: 1024px) {
    .order-way-sct .outer-odr-way-sct .left-sct-odr {
        max-width: 35%;
    }
    .order-way-sct .outer-odr-way-sct .right-sct-odr {
        max-width: calc(100% - 35%);
    }
}

@media (max-width: 900px) {
    .order-header .outer-main-box-header .order-date-sct {
        align-items: flex-start;
        grid-gap: 5px;
        flex-direction: column;
    }
    .order-header .order-date-sct h3 {
        font-size: 18px;
        line-height: 27.22px;
    }
    .order-header .outer-main-box-header .order-date-sct p {
        font-size: 12px;
        line-height: 15.6px;
    }
    .order-header .order-detail-sct a.order-detail-link {
        font-size: 14px;
        line-height: 21.17px;
    }
    .order-header .order-detail-sct a.order-detail-link svg {
        width: auto;
        max-width: 12px;
        height: 12px;
        margin-left: 7px;
    }
    section.order-header .outer-main-box-header {
        padding: 20px;
    }
    .order-way-sct .right-sct-odr h4 {
        font-size: 16px;
        line-height: 20.8px;
        margin-bottom: 5px;
    }
    .order-way-sct .right-sct-odr h2 {
        font-size: 20px;
        line-height: 24px;
    }
    .order-way-sct .right-sct-odr .progressbar {
        margin-top: 15px;
    }
    .track-odr-sct .track-item .content-sct-track h4 {
        font-size: 10px;
        line-height: 12px;
    }
    .track-odr-sct .track-item .content-sct-track h2 {
        font-size: 20px;
        line-height: 22px;
    }
    .track-odr-sct .track-item .content-sct-track p {
        font-size: 14px;
        line-height: 16.8px;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .track-item .content-sct-track {
        padding-bottom: 30px;
        padding-top: 0;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .progress-bar-track .progress-bar-line {
        top: 5rem;
        height: calc(100% - 10rem);
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .progress-bar-track .progress-bar-line-filled {
        top: 5rem;
    }
    section {
        padding: 0 2rem;
    }
}

@media (max-width: 600px) {
    .order-way-sct .outer-odr-way-sct {
        flex-direction: column-reverse;
        grid-gap: 20px;
    }
    .order-way-sct .outer-odr-way-sct .left-sct-odr {
        max-width: 70%;
    }
    .order-way-sct .outer-odr-way-sct .right-sct-odr {
        max-width: 100%;
    }
    .order-way-sct .outer-odr-way-sct {
        padding: 16px;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .track-item .content-sct-track {
        padding-left: 30px;
    }
    section.order-header .outer-main-box-header {
        padding: 16px;
    }
    section {
        padding: 0 1rem;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct {
        margin: 20px auto;
    }
    .order-way-sct .right-sct-odr .progressbar .progress-bar, .order-way-sct .right-sct-odr .progressbar .progress-bar-fillment {
        height: 10px;
    }

}

@media (max-width: 425px) {
    .track-odr-sct .outer-sct-track .inner-track-sct {
        max-width: 90%;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .track-item {
        grid-gap: 6px;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .track-item .content-sct-track {
        padding-left: 20px;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .track-item .content-sct-track{
        padding-bottom: 20px;
    }
    .track-odr-sct .outer-sct-track .track-item .icon-sct-track .svg-icon-sct {
        width: 45px;
        height: 45px;
        padding: 10px;
    }
    .track-odr-sct .outer-sct-track .inner-track-sct .progress-bar-track .progress-bar-line, .track-odr-sct .outer-sct-track .inner-track-sct .progress-bar-track .progress-bar-line-filled {
        left: 20px;
    }
}

@media (max-width: 360px) {
    section.order-header .outer-main-box-header {
        padding: 16px 7px;
    }
    .order-header .order-detail-sct a.order-detail-link svg {
        margin-left: 4px;
    }
}