@media screen and (max-width:1280px) {
    .chart-container .chart-items .chart-item {
        margin-right: 10px;
    }

    .dahsboard.page-container {
        max-width: 1185px;
    }

    .chart-container .delayed-report-chart .chart-item .tab-content .delayed-report-main-container {
        padding: 0 !important;

        .delayed-report-container {
            .first-chart {
                max-width: 433px;
            }

            .second-chart {
                max-width: 147px;
            }
        }
    }

    .chart-container .chart-items .chart-item .donet-chat-list .zone-donet .totel-text .text {
        width: 57px;
        text-align: center !important;
        line-height: 12px !important;
    }

    .chart-container .chart-items .chart-item.shipment-overview .shipment-over-view .shipment-overview-chart-dashboard {
        max-width: 460px;
        width: 100% !important;
    }

    .dahsboard.page-container .Dashboard-count-container .container-part2 .items .manifest-container .d-flex .item {
        max-width: 150px !important;
    }

    .dahsboard.page-container .Dashboard-count-container .flex-container .left-container {
        width: 32.8% !important;
    }

    .dahsboard.page-container .Dashboard-count-container .flex-container .left-container .count-item {
        margin-bottom: 9px !important;
    }

    .dahsboard.page-container .Dashboard-count-container .flex-container .right-container .count-item {
        margin-bottom: 9px !important;
    }

    //order details page
    .order-detail-page {
        &.page-container {
            width: 93%;

            .inner-container .detail-container {
                .left-container {
                    width: 65.5%;
                }

                .right-container {
                    width: 33%;
                }
            }
        }
    }

    // setting page
    .settings-page {
        padding: 30px 40px 0 40px;

        .inner-container {
            .left-container {
                max-width: 416px;
                width: 30%;
                min-width: unset !important;
            }
            .right-container{
                max-width: 848px;
                width: 68.6% !important;
                .courier-list{
                    width: 100% !important;
                }
                .item-list{
                    width: 100% !important;
                }
            }
        }
    }
    .call-activity-table{
        th,td{
            padding: 0;
            vertical-align: top;
        }
    }
    .daterangepicker.opensright:before {
        left: 350px !important;
    }
    .daterangepicker.opensright:after {
        left: 351px !important;
    }
}