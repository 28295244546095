.dashboard-donet {
    height: 321px;
    position: relative;

    &.zone-donet {
        height: 233px;
        width: 102px;
        margin-right: 15px;
    }

    .totel-text {
        .text {
            //styleName: Text md/Medium;
            font-family: InterMedium;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            color: #667085;
        }

        .value {
            //styleName: Display lg/Bold;
            font-family: InterBold;
            font-size: 48px;
            font-weight: 700;
            line-height: 60px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #101828;
        }
    }
}

.zone-wise-tat{
    .donet-chat-list{
        justify-content: unset !important;
    }
    
}

