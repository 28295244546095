.dahsboard.page-container {
    padding: 23px 40px 23px 40px;
    position: relative;
    p {
        margin: 0;
    }

    .title-container {
        position: relative;
        margin-bottom: 40px;

        .title-text {
            margin: 0;
            //styleName: Text xl/Semibold;
            font-family: 'Inter';
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            text-align: left;

        }

        .export-btn {
            width: 73px;
            height: 36px;
            padding: 8px 14px 8px 14px;
            gap: 8px;
            border-radius: 8px;
            // opacity: 0px;
            background: #486336;
            border: 1px solid #486336;
            box-shadow: 0px 1px 2px 0px #1018280D;

            //styleName: Text sm/Semibold;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #FFFFFF;

            position: absolute;
            right: 0;
            top: -3px;

        }
    }

    .Dashboard-count-container {
        .flex-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .left-container {
                // flex: 416px;
                max-width: 416px;
                width: 33%;
                // padding-right: 50px;

                .count-item {
                    min-height: 148px;
                    // padding: 20px 20px 0 20px;
                    border-radius: 8px;
                    background: #FFFFFF;
                    outline: 1px solid #E4E7EC;
                    // width: 416px;
                    width: 100%;
                    margin-bottom: 10px;

                    .inner-div {
                        padding: 20px 20px 20px 20px;
                    }

                    p {
                        margin: 0;
                    }

                    .total-text {
                        font-family: InterMedium;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        color: #667085;

                    }

                    .total-value {
                        font-family: InterSemiBold;
                        font-size: 36px;
                        font-weight: 700;
                        line-height: 38px;
                        text-align: left;
                        // background: #B168F4;
                        // background: linear-gradient(65.22deg, #B168F4 15.79%, #F875C1 48.69%, #FFC30A 81.76%);
                        background: #000000;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    &.new-order-container {

                        .d-flex {
                            justify-content: space-between;
                        }

                        .left-container-new {
                            width: 224px;
                            padding: 20px 15px 10px;
                            border-radius: 8px;
                            background: #F9FAFB;

                            .total-text {
                                text-align: center;
                                font-family: InterMedium;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;

                                .total-value {
                                    display: block;
                                    text-align: center;
                                    margin: 10px 0;
                                }
                            }

                            .text {
                                font-family: 'InterMedium';
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;
                                text-align: left;
                                color: #98A2B3;
                                background: #FFFFFF;
                                padding: 6px 15px;
                                border-radius: 8px;
                                margin-bottom: 5px;

                                .value {
                                    float: right;
                                    font-family: 'InterSemiBold';
                                    color: #101828;
                                }
                            }


                        }

                        .right-container-new {
                            width: 140px;
                            padding: 20px 16px 10px;
                            background: #F9FAFB;
                            border-radius: 8px;

                            .img-container {
                                display: block;
                                width: 100%;
                                text-align: center;
                            }

                            .total-text {
                                text-align: center;
                                font-family: InterMedium;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;

                                .total-value {
                                    display: block;
                                    text-align: center;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }

                    &.total-container {
                        .total-value {
                            margin-top: 10px;
                            display: inline-block;
                            font-family: InterBold;
                            font-size: 30px;
                        }

                    }
                }
            }

            .right-container {
                // flex: 848px;
                max-width: 848px;
                width: 66.4%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .count-item {
                    max-width: 416px;
                    width: 49.4%;
                    // min-height: 148px;
                    min-height: 205px;
                    display: inline-block;
                    vertical-align: top;
                    border-radius: 8px;
                    background: #FFFFFF;
                    outline: 1px solid #E4E7EC;
                    margin-bottom: 10px;

                    &.intransit-container {
                        .intransit-split {
                            .split-item:last-child {
                                width: 100%;
                            }
                        }
                    }

                    p {
                        margin: 0;
                    }

                    .inner-div {
                        padding: 20px 20px 0 20px;
                    }

                    .text {
                        font-family: InterMedium;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        margin-bottom: 50px;
                        color: #667085;

                        .value {
                            font-family: InterSemiBold;
                            font-size: 30px;
                            font-weight: 700;
                            line-height: 38px;
                            text-align: left;
                            // background: #B168F4;
                            // background: linear-gradient(65.22deg, #B168F4 15.79%, #F875C1 48.69%, #FFC30A 81.76%);
                            background: #000000;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            float: inline-end;
                            margin-right: 70px;

                        }
                    }

                    .inner-split {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        max-width: 376px;
                        margin-bottom: 15px;

                        // &::after {
                        //     content: '2';
                        //     display: block;
                        //     width: 10px;
                        //     height: 10px;
                        // }
                        &.intransit-split {
                            .split-item {
                                &:last-child {
                                    // width: 100%;
                                }
                            }
                        }

                        .split-item {
                            width: 50%;
                            padding: 0 20px;
                            border-right: 1px solid #D0D5DD;
                            text-align: left;
                            //styleName: Text sm/Medium;
                            font-family: InterSemiBold;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: center;
                            color: #98A2B3;

                            p {
                                display: inline-block;
                            }

                            span {
                                //styleName: Display sm/Bold;
                                font-family: InterSemiBold;
                                font-size: 30px;
                                font-weight: 700;
                                line-height: 38px;
                                text-align: center;
                                display: block;
                                text-align: center;
                                color: #101828;
                                margin-top: 5px;
                            }

                            &:last-child {
                                text-align: center;
                                border-right: unset;

                                span {
                                    display: block;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    &.ndr-container {
                        max-width: 100%;
                        width: 100%;

                        .ndr-split {
                            padding: 26px 24px 26px 24px;
                            background: #E0F2FE;
                            border-radius: 9px;
                            // opacity: 0px;
                            display: flex;
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 15px;

                            .split-item {
                                //styleName: Text sm/Medium;
                                font-family: InterMedium;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0%;
                                color: #98A2B3;

                                span {
                                    //styleName: Display sm/Semibold;
                                    font-family: InterSemiBold;
                                    font-size: 30px;
                                    font-weight: 600;
                                    line-height: 38px;
                                    display: block;
                                    text-align: center;
                                    color: #000000;

                                }

                            }

                        }
                    }
                }
            }
        }

        .container-part2 {
            flex-direction: row;
            justify-content: space-between;
            // margin-top: 20px;

            .items {
                display: flex;
                border-radius: 8px;
                max-width: 416px;
                width: 33%;
                // width: 100%;
                border: 1px solid #E4E7EC;
                background: #FFFFFF;

                .manifest-container {
                    padding: 20px;
                    max-height: 347px;
                    overflow-y: scroll;
                    width: 100%;

                    .title {
                        font-family: InterMedium;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        color: #667085;
                        margin-bottom: 10px;
                    }

                    .d-flex {
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .item {
                            max-width: 180px;
                            width: 100%;

                            .inner-item {
                                padding: 12px 14px;
                                position: relative;
                                background: #F9FAFB;
                                margin-bottom: 10px;
                                border-radius: 8px;

                                .text {
                                    //styleName: Text sm/Medium;
                                    font-family: InterMedium;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: #1D2939;

                                    span {
                                        position: absolute;
                                        margin-left: 10px;
                                        top: 20px;
                                    }
                                }

                                .value {
                                    //styleName: Display md/Semibold;
                                    font-family: InterSemiBold;
                                    font-size: 36px;
                                    font-weight: 600;
                                    line-height: 44px;
                                    letter-spacing: -0.02em;
                                    text-align: left;
                                    color: #000000;
                                }


                            }
                        }
                    }

                    .link {
                        text-align: center;
                        margin-top: 10px;

                        a {
                            //styleName: Text sm/Semibold;
                            font-family: InterSemiBold;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            text-align: left;
                            color: #364A29;
                            text-align: center;
                            text-decoration: none;
                        }
                    }
                }

                .chart-item {
                    padding: 20px;
                    width: 100%;

                    &.cancelled-container {
                        max-height: 347px;
                        overflow-y: scroll;
                    }

                    .title {
                        font-family: InterMedium;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 32px;
                        letter-spacing: 0%;
                        color: #667085;
                        margin-bottom: 10px;

                        .value {
                            font-family: InterSemiBold;
                            font-weight: 600;
                            font-size: 36px;
                            line-height: 44px;
                            text-align: center;
                            color: #000000;
                            // background: linear-gradient(65.22deg, #B168F4 15.79%, #F875C1 48.69%, #FFC30A 81.76%);
                            // -webkit-background-clip: text;
                            // -webkit-text-fill-color: transparent;
                            float: inline-end;
                        }
                    }

                    .link {
                        text-align: center;
                        margin-top: 10px;

                        a {
                            //styleName: Text sm/Semibold;
                            font-family: InterSemiBold;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            text-align: left;
                            color: #364A29;
                            text-align: center;
                            text-decoration: none;
                        }
                    }

                    .inner-split {
                        margin-top: 20px;

                        .item {
                            p {
                                //styleName: Text md/Medium;
                                font-family: InterMedium;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;
                                text-align: left;
                                color: #98A2B3;

                                span {
                                    float: right;
                                    color: #101828;
                                    font-family: InterMedium;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 24px;
                                    letter-spacing: 0%;

                                }
                            }
                        }
                    }
                }
            }
        }

        .ndr-main-container {
            .right-container {
                margin-top: 20px;
                max-width: 100%;
                width: 100%;

                .ndr-container {
                    .ndr-text {
                        margin-bottom: 10px;
                    }

                    .ndr-split {
                        background: #ECECEC !important;
                    }
                }
            }
        }
    }
}

.label-text-font {
    font-family: interSemiBold;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0%;

}

.dashboard-datepicker-range{
    height: 38px;
    padding: 10px 20px;
    position: absolute;
    right: 125px;
    top: 15px;
    width: 260px;
    z-index: 1;
}