.order-detail-page {
    border-left: 1px solid #D0D5DD;

    .inner-container {

        .header {
            padding-left: 40px;
            align-items: center;
            height: 76px;
            border-bottom: 1px solid #D0D5DD;

            .back {}

            .order-number {
                //styleName: Text xl/Semibold;
                font-family: InterSemiBold;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                text-align: left;
                margin: 0;
                margin-left: 10px;

            }
        }

        .detail-container {
            display: flex;
            padding: 0 40px;
            width: 100%;
            margin-top: 20px;
            justify-content: space-between;

            .left-container {
                width: 100%;
                max-width: 848px;
                flex-direction: column;

                .order-items {
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;

                    width: 100%;
                    padding: 20px;
                    background: #fff;
                    // margin-bottom: 20px;

                    .con-title {
                        //styleName: Text xl/Bold;
                        font-family: InterBold;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 30px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;

                        button {
                            width: 118px;
                            height: 36px;
                            padding: 8px 14px 8px 14px;
                            border-radius: 8px;
                            border: 1px;
                            opacity: 0px;
                            background: #FEF3F2;
                            border: 1px solid #FEF3F2;
                            box-shadow: 0px 1px 2px 0px #1018280D;
                            float: right;
                            font-family: InterSemibold;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            text-align: left;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            color: #B42318;

                        }
                    }

                    .item-list {
                        .item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 15px 0;
                            border-bottom: 1px solid #D0D5DD;

                            &:last-child {
                                border: none;
                                padding-bottom: 0;
                            }

                            .img-container {
                                display: flex;
                                // min-width: 50%;
                                // max-width: 50%;

                                .img {
                                    position: relative;
                                    display: block;
                                    overflow: hidden;
                                    background: var(--p-surface, white);
                                    min-width: 4rem;
                                    max-width: 100%;
                                    border-radius: 3px;
                                    width: 6rem;

                                    .product-img {
                                        position: absolute;
                                        z-index: 10;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        margin: auto;
                                        max-width: 100%;
                                        max-height: 100%;
                                    }

                                    &::after {
                                        content: '';
                                        position: relative;
                                        z-index: 20;
                                        display: block;
                                        padding-bottom: 100%;
                                        box-shadow: inset 0 0 0 1px rgba(33, 43, 54, 0.1);
                                        border-radius: 3px;
                                    }
                                }


                                .item-detail {
                                    margin-left: 10px;

                                    .title {
                                        //styleName: Text xl/Semibold;
                                        font-family: InterSemibold;
                                        font-size: 20px;
                                        font-weight: 600;
                                        line-height: 30px;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                        margin: 0;
                                        color: #000000;

                                    }

                                    .sku {
                                        //styleName: Text md/Medium;
                                        font-family: InterMedium;
                                        font-size: 16px;
                                        font-weight: 500;
                                        line-height: 24px;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                        color: #475467;


                                    }
                                }
                            }

                            .product-count {
                                //styleName: Text md/Medium;
                                font-family: InterMedium;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;
                                text-align: right;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #475467;

                            }

                            .product-total {
                                font-family: InterMedium;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;
                                text-align: right;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #475467;
                            }
                        }
                    }
                }

                .order-summary {
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;

                    .order-items {
                        button {
                            background: #E4EBE0;
                            border: 1px solid #E4EBE0;
                            color: #364A29;

                        }

                        .summer-detail {
                            margin-top: 20px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            .list-item {
                                width: 395px;

                                .lsit {
                                    //styleName: Text sm/Medium;
                                    display: flex;
                                    justify-content: space-between;

                                    .lable {
                                        font-family: InterMedium;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 20px;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                        color: #475467;
                                        display: block;
                                        width: 50%;
                                    }

                                    .value {
                                        //styleName: Text sm / Bold;
                                        font-family: InterBold;
                                        font-size: 14px;
                                        font-weight: 700;
                                        line-height: 20px;
                                        text-align: left;
                                        text-underline-position: from-font;
                                        text-decoration-skip-ink: none;
                                        color: #475467;
                                        display: block;
                                        width: 50%;

                                        &.unlabel button {
                                            //styleName: Text xs/Medium;
                                            font-family: InterMedium;
                                            font-size: 12px;
                                            font-weight: 500;
                                            line-height: 18px;
                                            text-align: center;

                                            padding: 2px 8px 2px 8px;
                                            border-radius: 4px;
                                            background: #F2F4F7;
                                            color: #344054;

                                        }

                                        &.fullfilment button {
                                            font-family: InterMedium;
                                            font-size: 12px;
                                            font-weight: 500;
                                            line-height: 18px;
                                            text-align: center;
                                            padding: 2px 8px 2px 8px;
                                            border-radius: 4px;
                                            background: #F4F3FF;
                                            color: #5925DC;
                                        }

                                        &.mode button {
                                            font-family: InterMedium;
                                            font-size: 12px;
                                            font-weight: 500;
                                            line-height: 18px;
                                            text-align: center;
                                            padding: 2px 8px 2px 8px;
                                            border-radius: 4px;
                                            background: #EFF8FF;
                                            color: #175CD3;
                                        }
                                    }
                                }
                            }
                        }
                    }


                }

                .call-information {
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;

                    display: flex;
                    flex-direction: row;

                    .left-con {
                        border-right: none;
                        border-radius: 8px 0px 0px 8px;
                        width: 38%;
                        padding-right: 0;

                        .form-select {
                            width: 165px;
                        }

                    }

                    .right-con {
                        border-left: none;
                        border-radius: 0 8px 8px 0px;
                        width: 70%;
                        padding: 20px 10px 0 10px;

                    }

                    .order-items {
                        .call-container {
                            display: flex;
                            flex-direction: row;

                            .call-status {
                                width: 184px;
                                display: flex;

                                .call-img-count {
                                    position: relative;
                                    margin-right: 10px;

                                    .count {
                                        position: absolute;
                                        right: 0;
                                        top: 0;
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 6px;
                                        background: #5E2F16;
                                        margin: 0;
                                        text-align: center;
                                        line-height: 0;

                                        span {
                                            color: #fff;
                                            font-family: DM Sans;
                                            font-size: 10px;
                                            font-weight: 500;
                                            line-height: 16px;
                                            text-underline-position: from-font;
                                            text-decoration-skip-ink: none;

                                        }
                                    }
                                }
                            }

                            .call-active {
                                width: 100%;
                            }

                            .status {
                                display: inline-block;
                                margin-top: 10px;
                                padding: 2px 8px 2px 8px;
                                border-radius: 4px;

                                font-family: InterMedium;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                                text-align: center;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;

                                &.accepted {
                                    background: #ADE1AD;
                                    color: #027A48;

                                }

                                &.declined {
                                    background: #FFC6DA;
                                    color: #231F20;
                                    ;
                                }

                                &.other {
                                    background: #FFEDBE;
                                    color: #fdb022;
                                }
                            }
                        }
                    }
                }

                .activity-logs {
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;

                    .activity-list {
                        height: 300px;
                        overflow-y: scroll;

                        .timeline-event {
                            width: 100%;
                        }
                    }
                }
            }

            .right-container {
                width: 100%;
                max-width: 416px;

                .con-title {
                    //styleName: Text xl/Bold;
                    font-family: InterBold;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 30px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    margin-bottom: 0;

                    img {
                        margin-right: 5px;
                        margin-top: -2px;
                    }

                    .link {
                        //styleName: Text sm/Semibold;
                        font-family: InterSemibold;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #026AA2;
                        float: right;
                    }
                }

                .border-bottom {
                    border-bottom: 1px solid #D0D5DD;
                    margin-bottom: 10px;

                    &:last-child {
                        border: none !important;
                        margin-bottom: 0 !important;
                    }
                }

                .tracking-number .con-title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .note-container {
                    width: 100%;
                    padding: 20px;
                    background: #fff;
                    margin-bottom: 20px;
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;

                }

                .customers {
                    width: 100%;
                    padding: 20px;
                    background: #fff;
                    margin-bottom: 20px;
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;

                    .name-detail {}

                    .cus-name-1 {
                        //styleName: Text md/Bold;
                        font-family: InterBold;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #475467;

                    }

                    .cus-name-2 {
                        //styleName: Text sm / Bold;
                        font-family: InterBold;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #475467;


                    }

                    .email,
                    .address {
                        //styleName: Text sm/Medium;
                        font-family: InterMedium;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #475467;
                    }

                    .phone {
                        display: inline-block;
                        //styleName: Text md/Bold;
                        font-family: InterBold;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        margin-top: 10px;
                    }
                }

                .fraud-analysis {
                    width: 100%;
                    padding: 20px;
                    background: #fff;
                    margin-bottom: 20px;
                    border: 1px solid #D0D5DD;
                    border-radius: 8px;

                    .range-input {
                        .slider-container {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 300px;
                            margin: 20px auto;

                            .range {
                                width: 100%;
                                height: 10px;
                                background-color: #E4E7EC;
                                position: relative;
                                border-radius: 8px;
                                margin-bottom: 10px;

                                .range-limt {
                                    position: absolute;
                                    height: 18px;
                                    border-radius: 8px;
                                    top: -3px;

                                    &::after {
                                        content: "";
                                        width: 10px;
                                        height: 10px;
                                        top: 3px;
                                        right: 2px;
                                        border-radius: 30px;
                                        background-color: #FFFFFF;
                                        position: absolute;
                                    }

                                    &.low {
                                        width: 33.33%;
                                        background-color: #486336;
                                    }

                                    &.medium {
                                        width: 66.66%;
                                        background-color: #8b8000;
                                    }

                                    &.high {
                                        width: 100%;
                                        background-color: red;
                                    }
                                }
                            }
                        }

                        .range-slider {
                            -webkit-appearance: none;
                            width: 100%;
                            height: 10px;
                            background: #d9dde1;
                            border-radius: 5px;
                            outline: none;
                            margin-bottom: 10px;
                        }

                        .range-slider::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background: #fff;
                            border: 2px solid #355e3b;
                            /* Green color for the border */
                            cursor: pointer;
                            box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
                        }

                        .range-slider::-moz-range-thumb {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background: #fff;
                            border: 2px solid #355e3b;
                            cursor: pointer;
                        }

                        .slider-labels {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            font-size: 14px;
                            color: #333;
                        }
                    }
                }
            }
        }
    }

}

.call-status-popup {
    .buttons {
        text-align: right;

        .submit {
            margin-top: 10px;
            margin-left: 10px;
        }
    }
}

.call-connection-popup {
    .buttons {
        text-align: right;

        .submit {
            margin-top: 10px;
            margin-left: 10px;
        }
    }
}