.settings-page {
    padding-top: 30px;

    .inner-container {
        max-width: 1280px;
        margin: 0 auto;

        // display: flex;
        .left-container {
            min-width: 416px;
            width: 416;
            display: inline-block;
            vertical-align: top;
            // height: 148px;
            padding: 0 24px;
            border-radius: 8px;
            border: 1px;
            border: 1px solid #D0D5DD;
            background: #FFFFFF;
            // margin-left: 50px;

            .list {
                display: flex;
                flex-direction: column;
                height: 148px;
                justify-content: center;

                .item {
                    //styleName: Text xl/Bold;
                    font-family: InterBold;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 30px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #667085;
                    margin: 0;
                    padding: 13px 0;
                    cursor: pointer;

                    &:first-child {
                        border-bottom: 1px solid #D0D5DD;
                    }

                    span {
                        float: right;
                    }

                    &.active {
                        color: #364A29;

                        span {
                            path {
                                stroke: #364A29;
                            }
                        }
                    }
                }
            }
        }

        .right-container {
            width: 848px;
            display: inline-block;
            margin-left: 15px;

            .carrier-section {
                display: flex;

                .text-section {
                    width: 694px;

                    .header {
                        font-family: InterSemibold;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 30px;
                        text-align: left;
                        color: #101828;
                    }

                    .text-content {
                        //styleName: Text sm/Medium;
                        font-family: InterMedium;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        color: #667085;
                        margin: 0;
                    }
                }

                .button-section {
                    width: 154px;
                    text-align: right;
                }
            }

            .courier-list {
                width: 848px;
                padding: 0 20px;
                border-radius: 8px;
                border: 1px solid #D0D5DD;
                background: #FFFFFF;
                margin-top: 30px;

                .list {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 20px 0;
                    align-items: center;
                    border-bottom: 1px solid #D0D5DD;

                    &:last-child {
                        border-bottom: none;
                    }

                    .c-img {
                        // height: 40px;
                        max-width: 140px;
                        width: 140px;
                    }

                    .name {
                        font-family: InterSemibold;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        color: #344054;
                        text-transform: capitalize;
                    }

                    .settings-btn {
                        outline: 1px solid #D0D5DD;
                        //styleName: Text sm/Semibold;
                        font-family: InterSemibold;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        color: #344054;
                    }

                }
            }

            .category-name {
                font-family: InterSemibold;
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                // text-align: center;
                color: #344054;
                margin-top: 20px;
                margin-bottom: 5px;
            }

            .item-list {
                width: 848px;
                padding: 0 20px;
                border-radius: 8px;
                border: 1px solid #D0D5DD;
                background-color: #FFFFFF;

                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 0;
                    border-bottom: 1px solid #D0D5DD;

                    .name {
                        margin: 0;

                        .inital {
                            width: 48px;
                            height: 48px;
                            border-radius: 200px;
                            background: #E4EBE0;
                            display: inline-block;
                            padding: 12px;
                            margin-right: 15px;
                            font-family: InterMedium;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 28px;
                            text-align: center;
                            color: #486336;
                        }

                        .user-name {
                            font-family: InterSemibold;
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            text-align: left;
                            color: #344054;
                        }

                    }

                    .more-btn {
                        width: 36px;
                        height: 36px;
                        padding: 8px;
                        border-radius: 8px;
                        border: 1px solid #D0D5DD;
                        box-shadow: 0px 1px 2px 0px #1018280D;

                        svg {
                            margin-top: -8px;
                            margin-left: 4px;
                        }

                    }
                }


            }
        }
    }

}

.green-btn {
    background-color: #486336;
    border: 1px solid #486336;
    font-family: InterSemibold;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
}