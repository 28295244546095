.gradient-donut {
    height: 240px;
    width: 450px;
    position: relative;
    svg{
        text{
            fill: #667085 !important;
            font-family: Inter !important;
            font-size: 10px !important;
            font-weight: 600;
            line-height: 18px;
            text-align: left;

        }
    }
    &.courier-split {
        height: 390px;
        width: 100%;

        &.full-w-wrap{
            .legend-container {
                bottom: 0;
                left: 0;
                width: 100%;
                margin: 0 auto;
                display: flex;
                padding: 0;
                flex-direction: row;
                justify-content: space-around;
                flex-wrap: wrap;
                .value{
                    display: none;
                }
                .legends{
                    .legend-content{
                        width: 100%;
                    }
                }
            }
        }
        .legend-container {
            bottom: 0;
            left: 0;
            width: 70%;
            justify-content: space-between;
            flex-direction: row;
            top: unset;
            margin: 0 auto;
            .value{
                display: none;
            }
        }
    }

    .legend-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        position: absolute;
        top: 50px;
        right: -25px;
        width: 150px;
        
        .legends {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding-right: 5px;
            .legend-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 80%;

                .label {
                    //styleName: Text xs/Medium;
                    font-family: InterMedium;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: left;
                    color: #98A2B3;

                }

                .value {
                    //styleName: Text sm/Semibold;
                    font-family: InterSemiBold;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: right;
                    color: #1D2939;

                }
            }
        }
    }
}