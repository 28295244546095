.Performance-by-courier {
    padding: 20px 20px;
    border: 1px solid;
    border-style: solid;
    border-color: #D0D5DD;

    .delivery-partners {
        .radio-button{
            position: absolute;
            opacity: 0;
            &:checked+.partners{
                color: #12190E;
                background-color: #D4DECE;

                span {
                    display: inline;
                    float: right;
                }
            }
        }
        .partners {
            min-width: 186px;
            padding: 8px 12px 8px 12px;
            background-color: #fff;
            color: #98A2B3;
            border-radius: 8px;
            font-family: InterMedium;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            border: none;
            margin-right: 20px;
            text-align: left;
            cursor: pointer;
            span {
                display: none;
            }
            
            &.active {
                color: #12190E;
                background-color: #D4DECE;

                span {
                    display: inline;
                    float: right;
                }
            }
        }
    }
}

