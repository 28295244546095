.parent-div {
    margin: 20px;
    width: 100%;
}
.orders-filter-page.page-container{
    padding: 0 40px 23px 40px;
}
.topview {
    display: flex !important;
    justify-content: space-between;
    margin-left: -40px;
    border-bottom: 1px solid #D0D5DD;
    margin-bottom: 20px;

    h4 {
        //styleName: Text xl/Medium;
        font-family: InterMedium;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: #101828;
        margin: 0;
        padding: 23px 20px 23px 40px;

    }
}

.fw-600 {
    font-weight: 600;
}

.quickBtn {
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #D0D5DD;
    border-radius: 5px;
    padding: 5px 10px;
    color: #344054;
}

.syncBtn {
    font-weight: 600;
    font-size: 14px;
    border: 1px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #7F56D9;
    color: white;
    margin-left: 10px;
}

.tabItem {
    color: #98A2B3;
    font-weight: 500;
    text-transform: none;
    min-width: auto;
    padding: 1px 10px;
    font-size: 14px;
}

.divider {
    color: #D0D5DD !important;
    margin-top: -3px !important;
    border-color: #D0D5DD !important;
}

.MuiTabs-root {
    min-height: 44px !important;
    max-height: 44px !important;
    &.order-tabs-item {
        .MuiTabs-indicator {
            display: none;
        }
    }

    .MuiTabs-flexContainer {
        // margin-left: 10px;

        .MuiTab-root {
            min-width: fit-content;
            padding: 0px 15px 0 30px;
            color: #98A2B3;
            text-transform: none;
            font-family: InterMedium;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            position: relative;
            background-color: #FFFFFF;
            height: 44px;
            min-height: 44px;
            border-radius: 8px 8px 8px 0;
            
            &::before {
                content: "";
                width: 14px;
                height: 14px;
                border-radius: 21px;
                background-color: #D0D5DD;
                position: absolute;
                left: 5px;
            }
        }

        .MuiTab-root.Mui-selected {
            min-width: fit-content;
            padding: 0px 30px;
            color: #486336;
            text-transform: none;
            font-family: 'InterMedium';
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            &::before{
                background: #486336;
            }
        }
    }

    .MuiTabs-indicator {
        background: #7F56D9;
        z-index: 1;
    }
}
.tab-list-item{
    position: relative;
    margin-right: 10px;
    height: 44px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 5px 0px 4px 0px #00000026;

    &.active{
        .MuiButtonBase-root{
            color: #486336;
            opacity: 1;
            &::before{
                background-color: #486336;
            }
        }
    }
    &::after {
        content: "";
        background-image: url(../../../assets/Rectangle-460.png);
        height: 60px;
        width: 39px;
        position: absolute;
        right: -28px;
        top: -10px;
        display: inline-block;
    }

}