.track-order-sec {
    padding: 100px 0;
    display: flow-root;
    background: #F2F2F2;
}
.track-card-img img{
    width: 100%;
}
@font-face {
    font-family: "cooperLtBT";
    src: url("../../../assets/fonts/CooperMdBT-Regular.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
.track-order-sec .title {
   text-align: center;
    margin: 0;
    margin-bottom: 24px;
    font-family: cooperLtBT;
    font-size: 40px;
    font-weight: 500;
    line-height: 32px;
}
.track-order-sec h5 {
    font-family: Madera;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: center;
    margin: 0;
    color: #131313d1;
    opacity: .7;
}
.track-card {
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
}
.track-card-img,.track-card-text {
    flex: 1;
}
.track-card {
    padding-top: 60px;
}
.track-order-form input {
    padding: 18px 20px 18px 56px;
    font-family: Madera;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    height: 56px;
    border-radius: 8px;
    border: 0;
    background: #fff !important;
    width: 100%;
}
.track-order-form span.icon {
    position: absolute;
    top: 50%;
    left: 20px;
    line-height: 0;
    transform: translatey(-50%);
}
.track-order-form input::placeholder {
    color: #808080;
    opacity: .5;
    }
.track-order-form .input-group {
    position: relative;
}
.track-order-form  span.icon {
    position: absolute;
    top: 50%;
    left: 20px;
    line-height: 0;
    transform: translatey(-50%);
}
.track-order-form  .input-group {
        margin-bottom: 16px;
    }
   .track-order-form    button.track-button {
        background: #507D3C;
        width: 95%;
        height: 56px;
        border: 0;
        border-radius: 8px;
        color: #fff;
        font-family: Madera;
        font-size: 20px;
        font-weight: 700;
        line-height: 30.24px;
        text-align: left;
        display: grid;
        place-items: center;
        margin-top: 40px;
    }
    @media only screen and (max-width: 767px) {
        .track-card-img,.track-card-text {
    flex: auto;
}
.track-order-sec .title {
    text-align: center;
    margin: 0;
    margin-bottom: 14px;
    font-size: 24px;
    font-weight: 500;
    line-height: 22px;
}
.track-order-sec h5 {
    font-family: Madera;
    font-size: 18px;
    line-height: 20.8px;

}
.track-card {
     padding-top: 30px;
    }
    .track-order-sec {
       padding:60px 0;

        }

      }