.chart-container {
    .chart-items {
        display: flex;
        justify-content: space-between;

        .chart-item {
            width: 100%;

            &.zone-wise {
                max-width: 710px;

                .zone-wise-chart {
                    .apexcharts-data-labels {
                        text {
                            fill: #667085;
                            font-family: Inter !important;
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 10px;
                            text-align: center;

                        }
                    }


                    .apexcharts-tooltip {
                        border: none !important;
                        border-radius: 8px;
                        width: 150px;

                        >div {
                            border: none !important;
                            padding: 10px 15px !important;
                            border-radius: 8px;

                            .zone-text {
                                //styleName: Text sm / Bold;
                                font-family: InterBold;
                                font-size: 14px;
                                font-weight: 700;
                                line-height: 20px;
                                text-align: center;
                                color: #FFFFFF;
                                display: inline-block;
                                margin-bottom: 5px;


                            }

                            .items-container {
                                margin-bottom: 5px;

                                .items {
                                    color: #98A2B3;
                                    //styleName: Text xs/Medium;
                                    font-family: InterMedium;
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 18px;
                                    text-align: center;

                                    .color-code {}


                                }

                                .value {
                                    float: right;
                                    //styleName: Text xs/Medium;
                                    font-family: InterMedium;
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 18px;
                                    text-align: center;

                                }
                            }

                            .total-text {
                                //styleName: Text xs/Medium;
                                font-family: 'InterMedium';
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 18px;
                                color: #98A2B3;

                                .value {
                                    float: right;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }

                }

                .legend-list {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin-top: 20px;

                    .legend {
                        display: inline-block;
                        margin-right: 15px;
                        //styleName: Text xs/Medium;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: center;
                        color: #98A2B3;

                        .color {
                            width: 10px;
                            display: inline-block;
                            height: 10px;
                            border-radius: 10px;
                            margin-right: 5px;
                        }
                    }

                }
            }

            &.delivery-attempts {
                max-width: 504px;
            }

            &.delayed-report {
                max-width: 100%;
            }


            &.zone-wise-tat {
                max-width: 49%;
                // height: 374px;

                .showZone {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0.03em;
                    text-align: center;
                    color: #98A2B3;
                    text-transform: uppercase;
                    margin-top: 40px;
                }
            }

            &.shipment-overview {
                max-width: 50%;

                .shipment-over-view {
                    position: relative;
                    max-width: 540px;
                    margin: 0 auto;

                    .titles {
                        position: absolute;
                        width: 100%;
                        //styleName: Text xs/Medium;
                        font-family: InterMedium;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: left;
                        color: #667085;
                        top: 20px;
                        padding: 0px 1px 0px 7px;

                        span:last-child {
                            float: right;
                        }
                    }

                    .values {
                        margin-top: 51px;
                        position: absolute;
                        float: right;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        height: 210px;
                        justify-content: space-between;
                        text-align: right;
                        //styleName: Text xs/Medium;
                        font-family: InterMedium;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        color: #667085;
                        // z-index: 1;
                    }

                    .shipment-overview-chart-dashboard {
                        width: 518px;
                    }

                    .apexcharts-yaxis {
                        text {
                            tspan {
                                //styleName: Text xs/Medium;
                                font-family: InterMedium;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                                fill: #667085;
                            }
                        }
                    }
                }
            }

            border-radius: 8px;
            border: 1px solid #E4E7EC;
            background: #FFFFFF;
            padding: 20px;

            margin-top: 30px;
            max-width: 710px;

            .head-title {
                display: inline-block;
                //styleName: Text lg/Semibold;
                color: #667085;
                font-family: InterMedium;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;

            }

            .charts-toggle-tab {
                float: right;
                margin: 0;
                border-bottom: unset;
                border-radius: 20px;
                border: 1px solid #F2F4F7;
                height: 34px;

                .nav-item {
                    .nav-link {
                        background-color: #FFFFFF;
                        border: none;
                        color: #98A2B3;
                        padding: 4px 12px;
                        border-radius: 20px 0px 0 20px;
                        // border: 1px solid #F2F4F7;

                        &.active {
                            background: #486336;
                            color: #FFFFFF;
                        }
                    }

                    &:last-child {
                        .nav-link {
                            border-radius: 0px 20px 20px 0px;
                        }
                    }

                }

            }

            .donet-dashboard {
                margin-top: 40px;
            }

            .donet-chat-list {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                width: 100%;

                .zone-donet {
                    .totel-text {
                        width: 58px;

                        .text {
                            font-family: Inter;
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 18px;
                            text-align: left;
                            color: #667085;

                        }

                        .value {
                            //styleName: Text sm / Bold;
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 20px;
                            text-align: center;
                            color: #101828;
                        }
                    }
                }
            }

            &.high-order-volume {
                position: relative;

                .h-line {
                    border-bottom: 1px solid #E4E7EC;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 100px;
                }

                .high-order-volume-container {
                    padding: 0 10px;

                    .high-order-volume-table {
                        width: 100%;

                        thead {
                            height: 50px;

                            tr {
                                border-bottom: 1px solid #E4E7EC;
                                
                                td {
                                    width: 33.33%;
                                    text-align: center;
                                    font-family: InterBold;
                                    font-size: 14px;
                                    font-weight: 700;
                                    line-height: 20px;
                                    color: #475467;

                                    &:first-child {
                                        text-align: left;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                &.color-0{
                                    background-color: #12190E;
                                    
                                }
                                &.color-1{
                                    background-color: #24321B;
                                    
                                }
                                &.color-2{
                                    background-color: #364A29;
                                    
                                }
                                &.color-3{
                                    background-color: #486336;
                                    
                                }
                                &.color-4{
                                    background-color: #5A7C44;
                                    
                                }
                                &.color-5{
                                    background-color: #799567;
                                    
                                }
                                &.color-6{
                                    background-color: #97AD89;
                                    
                                }
                                &.color-7{
                                    background-color: #B6C6AC;
                                    color: #667085;
                                    td{
                                        color: #667085;
                                    }
                                }
                                &.color-8{
                                    background-color: #D4DECE;                                    
                                    color: #667085;
                                    td{
                                        color: #667085;
                                    }
                                }
                                &.color-9{
                                    background-color: #E4EBE0;
                                    color: #667085;
                                    td{
                                        color: #667085;
                                    }
                                }
                                td {
                                    width: 33.33%;
                                    text-align: center;
                                    height: 30px;
                                    //styleName: Text sm/Medium;
                                    font-family: InterRegular;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    color: #E4E7EC;
                                    height: 30px;

                                    &:first-child {
                                        font-family: InterMedium;
                                        text-align: left;
                                        padding-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }


        }

    }

    .delayed-report-chart {
        .chart-item {
            border-radius: 8px;
            border: 1px solid #E4E7EC;
            background: #FFFFFF;
            padding: 20px;

            margin-top: 30px;

            .head-title {
                display: inline-block;
                //styleName: Text lg/Semibold;
               
                text-align: left;
                color: #667085;
                font-family: InterMedium;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
            }

            .charts-toggle-tab {
                float: right;
                margin: 0;
                border-bottom: unset;
                border-radius: 20px;
                border: 1px solid #F2F4F7;
                height: 34px;

                .nav-item {
                    .nav-link {
                        background-color: #FFFFFF;
                        border: none;
                        color: #98A2B3;
                        padding: 4px 12px;
                        border-radius: 20px 0px 0 20px;
                        // border: 1px solid #F2F4F7;

                        &.active {
                            background: #486336;
                            color: #FFFFFF;
                        }
                    }

                    &:last-child {
                        .nav-link {
                            border-radius: 0px 20px 20px 0px;
                        }
                    }

                }

            }

            .tab-content {
                .delayed-report-main-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0px 50px;

                    .delayed-report-container {
                        max-width: 583px;
                        display: flex;
                        width: 100%;

                        .first-chart {
                            width: 433px;
                            height: 100%;
                            display: inline-block;

                            .split-container {
                                font-family: Inter;
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 18px;
                                text-align: center;
                                color: #98A2B3;

                                span {
                                    display: block;
                                    font-family: InterSemiBold;
                                    font-size: 30px;
                                    font-weight: 700;
                                    line-height: 38px;
                                    text-align: center;
                                    color: #000000;
                                }
                            }
                        }

                        .second-chart {
                            width: 147px;
                            height: 100%;
                            display: inline-block;

                            .split-container {
                                font-family: Inter;
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 18px;
                                text-align: center;
                                color: #98A2B3;

                                span {
                                    display: block;
                                    color: #000000;
                                    font-family: InterSemiBold;
                                    font-size: 30px;
                                    font-weight: 700;
                                    line-height: 38px;
                                    text-align: center;

                                }
                            }
                        }
                    }

                    .delayed-report-data {
                        max-width: 420px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        // margin-top: 20px;
                        align-items: flex-end;

                        .item {
                            width: 184px;

                            .title {
                                font-family: Inter;
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 18px;
                                letter-spacing: 0.03em;
                                text-align: left;
                                color: #101828;
                                text-transform: uppercase;
                                padding: 13px 0;

                                .value {
                                    //styleName: Display sm/Bold;
                                    font-family: Inter;
                                    font-size: 30px;
                                    font-weight: 700;
                                    line-height: 38px;
                                    float: inline-end;
                                    color: #F04438;
                                    margin-top: -15px;
                                }
                            }

                            ul {
                                padding: 0;

                                li {
                                    &::marker {
                                        content: "";
                                    }

                                    padding: 10px 0;
                                    font-family: InterMedium;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 20px;
                                    text-align: left;
                                    color: #98A2B3;
                                    border-bottom: 1px solid #F2F4F7;

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    .color {
                                        width: 12px;
                                        height: 12px;
                                        border-radius: 2px;
                                        display: inline-block;
                                        margin-right: 7px;
                                    }

                                    .value {
                                        float: right;
                                        //styleName: Text sm / Bold;
                                        font-family: Inter;
                                        font-size: 14px;
                                        font-weight: 700;
                                        line-height: 20px;
                                        color: #000000;

                                        img {
                                            margin-left: 5px;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.shipment-overview {
    .table-container table {
        tbody {
            tr {
                td {
                    // padding: 4px;
                }
            }

            tr:nth-child(odd) {
                td {
                    padding-bottom: 0px;
                }
            }

            tr:nth-child(even) {
                td {
                    padding-top: 0px;
                }
            }
        }
    }
}

// .delivery-attempts {
//     .table-container {
//         width: 416px;
//         margin: 0 auto;
//         overflow: unset;

//         table {
//             thead {
//                 tr {
//                     th {
//                         //styleName: Text sm / Bold;
//                         font-family: InterBold;
//                         font-size: 14px;
//                         font-weight: 700;
//                         line-height: 20px;
//                         color: #000000;
//                         width: 120px;
//                     }
//                 }
//             }

//             tbody {
//                 tr:nth-child(2n) td {
//                     border: none;
//                 }

//                 tr {
//                     td.type {
//                         border-right: 1px solid #E4E7EC;
//                     }

//                     td {
//                         //styleName: Text sm / Bold;
//                         font-family: Inter;
//                         font-size: 14px;
//                         font-weight: 700;
//                         line-height: 20px;
//                         color: #000000;

//                     }
//                 }
//             }
//         }
//     }
// }

.average-delivery-time {
    .table-container {
        width: 416px;
        margin: 0 auto;
        overflow: unset;

        table {
            thead {
                tr {
                    th {
                        //styleName: Text sm / Bold;
                        font-family: InterBold;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000000;
                        width: 120px;
                    }
                }
            }

            tbody {
                tr:nth-child(2n) td {
                    border: none;
                }

                tr {
                    td.type {
                        border-right: 1px solid #E4E7EC;
                    }

                    td {
                        //styleName: Text sm / Bold;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000000;

                    }
                }
            }
        }
    }
}

.delayed-report-chart {
    .delayed-report-table {
        display: flex;
        width: 100%;
        padding: 0 80px 10px;
        justify-content: space-between;

        .title {
            //styleName: Text sm / Bold;
            font-family: InterBold;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: left;
            color: #000000;
            text-transform: uppercase;
            margin-bottom: 5px;
        }
    }

    .delayed-report-table-internal {
        width: 587px;
    }

    .delayed-report-table-external {
        width: 414px;
    }

    .table-container {
        margin: 0 auto;
        overflow: unset;
        border: 1px solid #E4E7EC;
        border-radius: 8px;
        padding: 8px 30px;

        table {

            thead {
                tr {
                    th {
                        //styleName: Text xs/Medium;
                        font-family: InterMedium;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 18px;
                        text-align: center;
                        padding: 8.9px;

                    }
                }
            }

            tbody {
                border: none;

                tr:nth-child(2n) td {
                    border: none;
                }

                tr {
                    td {
                        //styleName: Text sm / Bold;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 20px;
                        color: #000000;
                        border-bottom: 1px solid #E4E7EC;

                        &.type {
                            border: none;
                            text-transform: uppercase;
                        }

                        &.action {
                            // border: none;
                            width: 50px;
                        }
                    }
                }
            }
        }
    }
}

.table-container {
    width: 100%;
    max-width: 680px;
    overflow-x: auto;
    // border-radius: 10px;
    // background-color: white;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
            padding: 8.9px;
            text-align: center;
        }

        th {

            font-size: 14px;
            font-weight: bold;
        }

        td {
            font-size: 14px;
        }

        thead {
            tr {
                th {
                    //styleName: Text xs/Medium;
                    font-family: InterMedium;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: center;
                    color: #98A2B3;
                    padding-bottom: 5px;
                }
            }
        }

        tbody tr:first-child td:first-child {
            border-top-left-radius: 10px;
        }

        tbody tr:first-child td:last-child {
            border-top-right-radius: 10px;
        }

        tbody tr:last-child td:first-child {
            border-bottom-left-radius: 10px;
        }

        tbody tr:last-child td:last-child {
            border-bottom-right-radius: 10px;
        }

        tbody {
            border: 1px solid #E4E7EC;
            border-radius: 8px;

            tr:nth-child(2n) td {
                border-top: 1px solid #E4E7EC;
                border-bottom: 1px solid #E4E7EC;
            }

            tr:last-child td {
                border-bottom: none !important;
            }

            .type {
                //styleName: Text xs/Medium;
                font-family: InterMedium;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                text-align: left;
                color: #101828;
            }

            tr {
                td {
                    //styleName: Text sm/Semibold;
                    font-family: InterSemibold;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: center;
                    color: #101828;
                }
            }

            .status-title {
                font-weight: bold;
                padding-left: 10px;
                text-align: left;
                // color: #ff6b6b;
                border-bottom: 1px solid #E4E7EC;
            }

            .intransit .status-title {
                color: orange;
            }

            .delivered .status-title {
                color: #ffad46;
            }

            .delayed .status-title {
                color: #c08ef1;
            }

            .returned .status-title {
                color: #8265e8;
            }

            .action {
                text-align: center;
                border-bottom: 1px solid #E4E7EC;

                button {
                    padding: 5px 10px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;

                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }
    }



    // button
    .courier-split{
        .legend-container{
            width: 100%;
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
    }
}
