.order-status-main-container{
    padding: 20px 20px;
    border: 1px solid;
    border-style: solid;
    border-color: #D0D5DD;
    .order-status-container{
        .chart-container{
            .chart-items{
                .chart-item{
                    max-width: 611px;
                }
            }
        }
    }
}

