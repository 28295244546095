.tab-container {
    // position: relative;

    .datepicker-range {
        position: absolute;
        width: 260px;
        right: 125px;
        padding: 10px 20px 10px 20px;
        top: 15px;
        height: 38px;
    }
}

.dashboard-tabs {
    margin-top: 20px;
    margin-bottom: 0px !important;
    border: none;

    .nav-item {
        padding-right: 20px;

        .nav-link {
            position: relative;
            padding: 11px 26px;
            //styleName: Text sm/Medium;
            font-family: InterMedium;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #667085;
            border-radius: unset;

            &.active {
                color: #486336;

                &::before {
                    background: #486336;
                }
            }

            &::before {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 21px;
                position: absolute;
                background: #667085;
                left: 7px;
                top: 17px;
            }
        }
    }
}