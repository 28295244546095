.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    /* Adjust the size of the checkbox */
    margin-right: 15px;
    /* Space between checkboxes */
    cursor: pointer;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    /* Size of the circular checkbox */
    width: 20px;
    /* Size of the circular checkbox */
    background-color: #EAECF0;
    // border: 2px solid #000;
    /* Border color of the checkbox */
    border-radius: 50%;
    /* Makes it a circle */
}

.checkbox-container:hover input~.checkmark {
    background-color: #EAECF0;
    /* Change background color on hover */
}

.checkbox-container input:checked~.checkmark {
    background-color: #D1FADF;
    /* Background color when checkbox is checked */
    background-color: #D1FADF;
    // border: 2px solid;
    /* Border color when checkbox is checked */
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 7px;
    /* Adjust the position of the checkmark inside the circle */
    top: 2px;
    /* Adjust the position of the checkmark inside the circle */
    width: 6px;
    height: 12px;
    border: solid #039855;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    /* Rotates the checkmark */
}


.all-done-page {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 80%;

    .container {
        width: 440px;
        text-align: center;
        margin: 0 auto;
    }
}

.icon {
    position: absolute;
    left: 10px;
    top: 50%;

    & img {
        position: relative;
        top: 5px;
    }

    & i {
        position: relative;
        top: 6px;
    }

}

.inputWithIcon {
    padding: 17px;
    padding-left: 35px;
    font-family: Inter !important;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;

    border-radius: 8px;
    &::placeholder {
        color: #667085;
        opacity: 0.5;
      }
    // width: 269px;
}

.headerLogo {
    width: 40px;
    height: 100%;
}

.pageLogo {
    width: 60px;
    height: 100%;
}

.forgotPassword {
    color: #026AA2;
    font-family: InterSemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    // text-align: left;
}

.loginLeftside {
    .titles {
        .h1-title {
            font-family: 'InterSemiBold';
            font-weight: 600;
            line-height: 44px;
            font-size: 36px;
            color: #101828;
        }

        .title-description {
            font-family: 'InterSemiBold';
            font-weight: 500;
            // line-height: 44px;
            font-size: 18px;
            color: #667085;
            margin-bottom: 0;
        }
    }
}

.header-logo {
    width: 200px;
}

.logo-alone {
    width: 100px;
}

.label {
    font-family: Inter;
    font-size: 19.25px;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
    color: #344054;
}

.daterangepicker .ranges li {
    color: #364A29 !important;

    &.active {

        background-color: #E4EBE0 !important;
    }
}

.daterangepicker {
    .calendar-table {
        tbody {
            td {
                height: 40px;
                width: 40px;
            }
        }
    }
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #486336 !important;
    right: 20px !important;

}

.daterangepicker .applyBtn {
    background-color: #486336 !important;
}

.react-responsive-modal-closeButton {
    top: 20px !important;
    right: 20px !important;

}

.modal-dialog-Custom {
    max-width: 635px !important;
    margin: 2rem auto !important;
    max-height: 400px !important;
}

.submit-btn {
    padding: 12px 20px 12px 20px;
    gap: 8px;
    border-radius: 8px;
    border: 1px 0px 0px 0px;
    // opacity: 0px;
    background: #486336;
    border: 1px solid #486336;
    font-family: InterMedium;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #fff;

}

.submit-btn:hover {
    background: #486336;
    border: 1px solid #486336;
    color: #fff;
}

.back-container {
    position: relative;

    .back-logo {
        position: absolute;
        left: 0;
    }

    .text {
        margin-left: 60px;

    }

    h1 {
        margin-bottom: 0;
        //styleName: Display sm/Medium;
        font-family: InterMedium;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: left;
        text-transform: capitalize;

    }

    p {
        font-family: InterSemibold;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #667085;
    }

    p.all-done-help {
        font-family: InterMedium;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        margin-top: 20px;
        color: #98A2B3;
    }
}

.text-left {
    text-align: left;
}

.pointer{
    cursor: pointer;
}