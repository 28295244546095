.side-menu {
    width: 80px;
    min-width: 80px;
    height: 100vh;
    display: inline-block;
    // 
    .pos-fixed{

        position: fixed;
    }
    &.border-right{
        border-right: 1px solid #D0D5DD;
    }
    .top-container {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .middle-container{
        display: flex;
        align-items: center;
        height: 100vh;
        background-color: #F2F4F7;
        .middle-contrainer-1 {
            background-color: hsl(0, 0%, 100%);
            position: relative;
            width: 80px;
            &::before {
                content: "";
                background-image: url('../../../assets/images/sidebar/recttop.png');
                height: 91px;
                display: inline-block;
                width: 100%;
                position: absolute;
                top: -91px;
                background-repeat: no-repeat;
            }
    
            &::after {
                content: "";
                background-image: url('../../../assets/images/sidebar/rectbottom.png');
                height: 91px;
                display: inline-block;
                width: 100%;
                position: absolute;
                background-repeat: no-repeat;
            }
            .m-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                .item{
                    cursor: pointer;
                    padding: 12px;
                    max-height: 48px;
                    // margin-bottom: 10px;
                    &.active{
                        background: linear-gradient(90deg, #A4FEB7 0%, #F7F05F 100%);
                        border-radius: 50%;
                    }
                }
            }
    
        }
    }
    .bottom-container{
        position: absolute;
        bottom: 0;
    }
    
}
.profile-container-logout{
    margin-left: 5px;
    margin-bottom: 5px;
}