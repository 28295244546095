$toast-error-color: #e21717;
$toast-success-color: rgb(0, 149, 127);

.base-toast-div {
    height: 90px;
    display: flex;
}

.custom-toast-succes-icon {
    color: $toast-success-color;
    font-size: 30px;
}

.custom-toast-title-success {
    font-weight: bold;
    color: $toast-success-color;
    margin-bottom: 10px;
}

.custom-toast-body-message {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 500;
}

.custom-toast-title-error {
    font-weight: bold;
    color: $toast-error-color;
    margin-bottom: 10px;
}

.custom-toast-error-icon {
    color: $toast-error-color;
    font-size: 30px;
}