.new-order.popup-container {
    position: relative;
    width: 100%;
    height: 75px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .popup-body {
        position: absolute;
        padding: 25px 15px 25px 35px;
        left: 20%;
        right: 20%;
        bottom: 0;
        top: 0;
        text-align: left;
        margin: auto;
        border-radius: 15px;
        border-color: black;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        height: 75px;
        z-index: 100;

        .btn {
            font-size: 14px;
            font-family: 'InterSemiBold';
            font-weight: 600;
            margin-right: 10px;
            color: #6941C6;
            background: #F9F5FF;
            border: none;
        }

        .btn.cancel-order {
            color: #B42318;
            background: none;
            border: 1px solid #FDA29B;
        }

        .close-icon {
            margin-top: 3px;
        }
    }
}
.action-buttons.new-order{
    width: 150px;
}
.barcode{
    display: inline-block;
    vertical-align: top;
    .barcodeManifest{
        background-color: #486336;
        border: 1px solid #486336;
        color: #fff;
    }
}
