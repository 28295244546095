.apexcharts-text {
    tspan {
        fill: #98A2B3;
        font-family: InterRegular;
        font-size: 10px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-align: right;

    }
}