.user-details-page {
    .inner-container {
        .detail-container {
            .left-container {
                .order-items {
                    .con-title {
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }

                    .item-list {
                        .label {
                            width: 200px;
                            font-family: InterMedium;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            text-align: left;
                            color: #475467;

                        }

                        .user-input {
                            width: 300px;
                            display: inline-block;
                            margin: 10px 0;
                        }

                        .value {
                            font-family: InterBold;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 20px;
                            color: #475467;
                        }
                    }

                    .permisstion-list {
                        .permisstion-item {
                            .form-check-input {}

                            .form-check-label {
                                font-family: InterMedium;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                color: #344054;
                            }
                        }
                    }

                    .manage-staf {
                        .suspend-access {
                            &:first-child {
                                border-bottom: 1px solid #D0D5DD;
                                padding-bottom: 30px;
                                margin-bottom: 30px;
                            }

                            &:last-child {
                                margin-bottom: 10px;
                            }

                            .sub-title {
                                //styleName: Text lg/Medium;
                                font-family: InterMedium;
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 28px;
                                text-align: left;
                                color: #1D2939;
                            }

                            .sub-content {
                                font-family: InterMedium;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                text-align: left;
                                color: #667085;

                            }

                            .suspend-btn {
                                background-color: #FEF3F2;
                                color: #B42318;
                                box-shadow: 0px 1px 2px 0px #1018280D;
                                border: 1px solid #FEF3F2;
                                font-family: InterSemibold;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                                text-align: left;

                            }

                            .delete-btn {
                                border: 1px solid #D92D20;
                                background-color: #D92D20;
                                box-shadow: 0px 1px 2px 0px #1018280D;
                                font-family: InterSemibold;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 24px;
                                text-align: left;
                                color: #FFFFFF;

                            }
                        }
                    }
                    &.user-add{
                        .item-list{
                            display: inline-block;
                            width: 49%;
                        }
                    }
                }
            }
        }
    }

}

.text-right {
    text-align: right;
}
.submit-btn{

}